<template>
  <div>
    <div>
      <b-modal
        :active.sync="isLoading"
        :width="400"
        :can-cancel="[false, false, false]"
      >
        <div class="modal-card" style="width: auto">
          <section class="modal-card-body">
            <div class="field" style="margin-top: 25px;">
              <p style="margin-bottom: 15px; text-align: center;">
                Carregando informações, por favor, não recarregue a página até
                que o processo seja concluído.
              </p>
              <progress class="progress is-small is-primary" max="100"
                >15%</progress
              >
            </div>
          </section>
        </div>
      </b-modal>
    </div>
    <div class="toolbar">
      <b-button v-if="plansTable" type="is-success" @click="addPlan()">Adicionar Plano</b-button>
      <b-button v-if="plansForm && 'add,edit'.indexOf(plansFormAction) !== -1" type="is-success" @click="savePlanForm()">Salvar</b-button>
      <b-button v-if="plansForm" type="is-danger" outlined @click="resetForm()">Cancelar</b-button>
    </div>

    <div class="has-background-bluedark2">

      <b-table
        blueDark2
        v-if="plansTable"
        :data="plans"
        :mobile-cards="true"
        default-sort="id"
        default-sort-direction="asc">

        <template slot-scope="props" slot="header">
          <div v-if="props.column.sortable" class="label-table centralize-row">
            <span v-if="props.column.sortable">
              <b-icon icon="sort" pack="fas" size="is-small"></b-icon>
            </span>
            <p>{{ props.column.label }}</p>
          </div>
          <div v-else class="label-table centralize-row">
            <p>{{ props.column.label }}</p>
          </div>
        </template>

        <template slot-scope="props">

          <b-table-column field="acoes" label="Ações" centered>
            <b-button type="is-primary" icon-pack="fas" size="is-small" icon-right="search" outlined @click="viewPlan(props.row)"/>
            <b-button type="is-warning" icon-pack="fas" size="is-small" icon-right="edit" outlined @click="editPlan(props.row)"/>
            <b-button type="is-danger" icon-pack="fas" size="is-small" icon-right="times" outlined @click="delPlan(props.row)"/>
          </b-table-column>

          <b-table-column field="ativo" label="Ativo">
            <b-switch type="is-success" v-model="props.row.ativo" @input="toggleFlag('ativo', props.row)"></b-switch>
          </b-table-column>

          <b-table-column field="bloqueado" label="Bloqueado">
            <b-switch type="is-success" v-model="props.row.bloqueado" @input="toggleFlag('bloqueado', props.row)"></b-switch>
          </b-table-column>

          <b-table-column field="id" label="Id" sortable searchable right numeric>
            {{ props.row.id }}
          </b-table-column>

          <b-table-column field="influencer.nome_midia" label="Influenciador" sortable searchable left>
            {{ props.row.influencer.nome_midia }}
          </b-table-column>

          <b-table-column field="periodo" label="Período" sortable searchable left>
            <div>{{ props.row.periodo }}</div>
          </b-table-column>

          <b-table-column field="descricao" label="Descrição" sortable searchable left>
            <div>{{ props.row.descricao }}</div>
          </b-table-column>

          <b-table-column field="valor" label="Valor" sortable searchable right numeric>
            {{ props.row.valor }}
          </b-table-column>

        </template>

        <template slot="empty">
            <section class="section">
                <div class="content has-text-grey has-text-centered">
                    <p>
                        <b-icon
                            icon="emoticon-sad"
                            size="is-large">
                        </b-icon>
                    </p>
                    <p>Nenhum Registro.</p>
                </div>
            </section>
        </template>
      </b-table>
    </div>

    <div v-if="plansForm" class="columns is-multiline notification has-background-dark">
      <b-tabs type="is-toggle" expanded>
        <b-tab-item label="Dados Gerais" icon="info" icon-pack="fas">
          <div class="columns is-multiline">

            <div class="column is-full">
              <div class="columns is-multiline">

                <div class="column is-4">
                  <b-field label="Período">
                    <b-input type="text" v-model="form.periodo" :disabled="plansFormAction == 'view'" required></b-input>
                  </b-field>
                </div>
                <div class="column is-4">
                  <b-field label="Descrição">
                    <b-input type="text" v-model="form.descricao" :disabled="plansFormAction == 'view'" required></b-input>
                  </b-field>
                </div>
                <div class="column is-4">
                  <b-field label="Valor">
                    <b-input type="number" maxlength='9999' max='999.99' step='0.01' pattern="^\d*(\.\d{0,2})?$" v-model="form.valor" :disabled="plansFormAction == 'view'" required></b-input>
                  </b-field>
                </div>
              </div>
            </div>

            <div class="column is-3">

              <b-field>
                <template slot="label">
                  <p>Influenciador</p>
                  <small>Selecione um influenciador, caso o plano seja apenas para seus seguidores</small>
                </template>
                <b-select v-model="form.influencer" :disabled="plansFormAction == 'view'">
                  <option
                    v-for="op of influencers"
                    :key="op.id"
                    :value="op">
                    {{ op.nome_midia }}
                  </option>
                </b-select>
              </b-field>

              <b-field>
                <template slot="label">
                  <p>Ordem de Exibição</p>
                  <small>Define a sequência de exibição dos cards</small>
                </template>
                <b-input type="number" v-model="form.ordem" :disabled="plansFormAction == 'view'" required></b-input>
              </b-field>

              <b-field>
                <template slot="label">
                  <p>Expiração</p>
                  <small>Qtd. de dias para expiração a partir da inscrição</small>
                </template>
                <b-input type="number" v-model="form.expiracaoDias" :disabled="plansFormAction == 'view'"></b-input>
              </b-field>

              <b-field>
                <template slot="label">
                  <p>Expiração (data e hora)</p>
                  <small>Data e Hora fixa para expiração</small>
                </template>
                <b-datetimepicker v-model="form.expiracao" icon="calendar-today" editable trap-focus :disabled="plansFormAction == 'view'"></b-datetimepicker>
              </b-field>

            </div>

            <div class="column is-9">
              <b-field>
                <template slot="label">
                  <p>Descrição Detalhada do Plano</p>
                  <small>Essa descrição será exibida no card de assinatura para que o usuário possa ter uma informação detalhada do plano, principalmente com relação aos acessos, antes de realizar a compra.</small>
                </template>
                <mc-wysiwyg id="detalhes" v-model="form.detalhes" :disabled="plansFormAction == 'view'"></mc-wysiwyg>
              </b-field>
            </div>

            <div class="column is-full">
              <b-field>
                <template slot="label">
                  <p>Mensagem de Boas Vindas</p>
                  <small>Essa mensagem será exibida após a finalização do cadastro na tela de confirmação da assinatura, podendo descrever principalmente os próximos passos para realizar o login.</small>
                </template>
                <b-input type="textarea" v-model="form.boasVindas" :disabled="plansFormAction == 'view'"></b-input>
                <!-- <mc-wysiwyg id="boasVindas" v-model="form.boasVindas" :disabled="plansFormAction == 'view'"></mc-wysiwyg>
                <text-editor :pId="'boasVindas'" :pDisabled="plansFormAction == 'view'" v-on:change="form.boasVindas = $event"></text-editor> -->
              </b-field>
            </div>

          </div>
 
        </b-tab-item>
 
        <b-tab-item label="Cupons" icon="ticket-alt" icon-pack="fas">
          <div class="column is-full">
            <Cupons :plansFormAction="plansFormAction" :cupons="form.cupons" @change="val => handlerCupons(val)"></Cupons>
          </div>
        </b-tab-item>
        <b-tab-item label="Acessos" icon="sign-in-alt" icon-pack="fas">
          <div class="column is-full">
            <Access :plansFormAction="plansFormAction" :access="form.acessos" @change="val => handlerAcessos(val)"></Access>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>

  </div>
</template>

<script>
import Cupons from "./Cupons";
import Access from "./Access";
import moment from "moment";
// import textEditor from '@/components/helpers/wysiwygEditor';

import { mapActions, mapGetters } from "vuex";
export default {
  name: "Plans",
  components: {
    Cupons, 
    Access,
    // "text-editor": textEditor
  },
  data() {
    return {
      plans: [],
      plansTable: false,
      plansForm: false,
      plansFormAction: "",
      flagSelect: {},
      cupons: [],
      access: [],
      isLoading: false,
      form: {},
      reseted_form: {},
      influencers: []
    };
  },
  computed: {
    ...mapGetters(["userId"])
  },
  async created() {

    let loader = this.$loading.show()

    this.plans = await this.getAllPlans();
    this.influencers = (await this.getInfluencers()).data;

    this.plansTable = true;
    this.plansForm = false; 
  
    loader.hide()
  },
  methods: {
    ...mapActions(["getUser", "getInfluencers", "getAllPlans", "flagPlan", "savePlan", "deletePlan"]),
    async toggleFlag(field, plan){

      this.flagSelect = {field, plan}

      let active, title, msg, confirm

      if (field == "ativo"){
        active = plan.ativo
        title = (active) ? "Ativar Plano" : "Desativar Plano"
        msg = (active) ? "O Plano será ativado e liberado para a visualização/inscrições dos usuários." : "O Plano será desativado e ficará oculto para os usuários."
        confirm = (active) ? "Ativar" : "Desativar"
      } else {
        active = plan.bloqueado
        title = (active) ? "Bloquear Plano" : "Desbloquear Plano"
        msg = (active) ? "O PLano será bloqueado e poderá ser visualizado mas não selecionado pelos usuários." : "O Plano será desbloqueado e ficará liberado para inscrições dos usuários."
        confirm = (active) ? "Bloquear" : "Desbloquear"
      }

      this.$buefy.dialog.confirm({
        title: title,
        message: '<p>Tem certeza que deseja alterar o flag do campo '+field+'?</p><p>'+msg+'</p>',
        confirmText: confirm,
        cancelText: 'Cancelar',
        type: (active) ? 'is-success' : 'is-danger',
        hasIcon: true,
        onCancel: async () => {
          if (this.flagSelect.field == "ativo"){
            this.flagSelect.plan.ativo = !this.flagSelect.plan.ativo
          } else {
            this.flagSelect.plan.bloqueado = !this.flagSelect.plan.bloqueado
          }
        }, 
        onConfirm: async () => {

          try {
            this.isLoading = true;

            let response = await this.flagPlan(this.flagSelect);

            if (response.ok){
              if (response.data.data.affectedRows == 1){
                this.$toasted.success("Flag do campo "+this.flagSelect.field+" alterado com sucesso!", {
                  duration: 3000,
                  position: "top-center"
                });
              }
            } else {
              console.log(response.statusText);
              this.$toasted.error("Ocorreu um problema ao tentar alterar o flag do campo "+this.flagSelect.field+"! Erro: "+response.statusText, {
                duration: 3000,
                position: "top-center"
              });
            }

            this.isLoading = false;

          } catch (err) {
            this.isLoading = false;
            console.log(err);
            this.$toasted.error("Ocorreu um problema ao tentar alterar o flag do campo "+this.flagSelect.field+"!", {
              duration: 3000,
              position: "top-center"
            });
          }

        }
      })

    },
    async addPlan() {
      this.form = JSON.parse(JSON.stringify(this.reseted_form));
      this.plansTable = false
      this.plansForm = true
      this.plansFormAction = "add"
    },
    async resetForm(){
      let loader = this.$loading.show()
      this.plansForm = false; 
      this.plansTable = false;
      this.plans = await this.getAllPlans();
      this.influencers = (await this.getInfluencers()).data;
      this.form = JSON.parse(JSON.stringify(this.reseted_form));
      this.plansTable = true;
      await this.getUser(this.userId)
      loader.hide()
    },
    async savePlanForm() {

      if (await this.validPlan()){
        
        try {
          this.isLoading = true;

          let params = JSON.parse(JSON.stringify(this.form))

          params.expiracao = (params.expiracao) ? moment(params.expiracao).format('YYYY-MM-DD HH:mm:ss') : null
          params.cupons.map(c => {
            c.vigencia_ini = (c.vigencia_ini) ? moment(c.vigencia_ini).format('YYYY-MM-DD HH:mm:ss') : null
            c.vigencia_fim = (c.vigencia_fim) ? moment(c.vigencia_fim).format('YYYY-MM-DD HH:mm:ss') : null
          })

          let data = await this.savePlan({
            form: params
          });

          if (data.error){
            let acao = (this.plansFormAction == 'add') ? 'Inclusão' : 'Edição'
            this.$toasted.error(acao+" do Plano não permitido! "+data.error, {
              duration: 3000,
              position: "top-center"
            });
          } else {
            let acao = (this.plansFormAction == 'add') ? 'incluído' : 'alterado'
            this.$toasted.success("Plano "+acao+" com sucesso!", {
              duration: 3000,
              position: "top-center"
            });
            this.resetForm()
            this.getUser(this.userId)
          }
          this.isLoading = false;

        } catch (err) {
          this.isLoading = false;
          console.log(err);
          this.$toasted.error("Ocorreu um problema ao tentar registrar o Plano!", {
            duration: 3000,
            position: "top-center"
          });
        }
      }
    },
    async validPlan() {

      let valid = true
      let msgError = ""

      if (this.form.acessos.length == 0){
        msgError += "<p>* Permissão de acessos não definida</p>"
      }

      if (msgError !== ""){
        valid = false
        this.$buefy.dialog.alert({
          title: "ATENÇÃO!",
          message: msgError,
          type: "is-danger",
          hasIcon: true
        });
      }

      return valid
    },
    async viewPlan(plan) {

      this.fillForm(plan)

      this.plansTable = false
      this.plansForm = true
      this.plansFormAction = "view"
    },
    async editPlan(plan) {

      this.fillForm(plan)

      this.plansTable = false
      this.plansForm = true
      this.plansFormAction = "edit"
    },
    async delPlan(plan) {

      if (plan){

        this.fillForm(plan)
        this.plansFormAction = "delete"

        this.$buefy.dialog.confirm({
          title: 'Exclusão do Plano',
          message: 'Tem certeza que deseja excluir este Plano?',
          confirmText: 'Excluir',
          cancelText: 'Cancelar',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {

            try {
              this.isLoading = true;

              let data = await this.deletePlan({
                id_plano: this.form.id
              });

              if (data.error){
                this.$toasted.error("Exclusão do Plano não permitida! "+data.error, {
                  duration: 3000,
                  position: "top-center"
                });
              } else {
                this.$toasted.success("Plano excluído com sucesso!", {
                  duration: 3000,
                  position: "top-center"
                });
                this.resetForm()
              }
              this.isLoading = false;

            } catch (err) {
              this.isLoading = false;
              console.log(err);
              this.$toasted.error("Ocorreu um problema ao tentar excluir o Plano!", {
                duration: 3000,
                position: "top-center"
              });
            }

          }
        })
      }
    },
    fillForm(plan) {
      this.form = JSON.parse(JSON.stringify(plan))
      this.form.expiracao = (this.form.expiracao) ? new Date(this.form.expiracao) : null
      this.form.influencer = this.influencers.find(i => i.id == plan.influencer.id)
    },
    handlerCupons(cupons) {
      this.form.cupons = cupons;
    },
    handlerAcessos(acessos) {
      this.form.acessos = acessos;
    }
  }
};
</script>

<style scoped>
.toolbar {
  text-align: right;
  padding: 1.5em;
}
</style>
