<template>
  <section id="AccessForm" v-if="render">
    <div class="has-background-dark">

      <b-table
        :data="accessTable"
        :mobile-cards="true"
        ref="tableAccess"
        detailed
        custom-detail-row
        :default-sort="['name', 'asc']"
        detail-key="id"
        :show-detail-icon="true">

        <template slot-scope="props" slot="header">
          <div v-if="props.column.sortable" class="label-table centralize-row">
            <span v-if="props.column.sortable">
              <b-icon icon="sort" pack="fas" size="is-small"></b-icon>
            </span>
            <p>{{ props.column.label }}</p>
          </div>
          <div v-else class="label-table centralize-row">
            <p>{{ props.column.label }}</p>
          </div>
        </template>

        <template slot-scope="page">

          <b-table-column field="status" label="Acesso?">
            <b-switch type="is-success" v-model="page.row.status" @input="toggleFlag('page',page.row)"></b-switch>
          </b-table-column>

          <b-table-column field="acesso" label="Status">
            <span :class="{'tag is-success': page.row.acesso == 'Total', 'tag is-warning': page.row.acesso == 'Parcial', 'tag is-danger': page.row.acesso == 'Restrito'}">
              {{ page.row.acesso }}
            </span>
          </b-table-column>
<!-- 
          <b-table-column field="id" label="Id" right numeric>
            {{ page.row.id }}
          </b-table-column>
 -->
          <b-table-column field="name" label="Tela" left>
            {{ page.row.name }}
          </b-table-column>

          <b-table-column field="description" label="Descrição" left>
            {{ page.row.description }}
          </b-table-column>

        </template>

        <template slot="detail" slot-scope="page">

          <tr class="detail">

            <td></td>
            <td colspan="4">

              <b-table
                :data="page.row.pageFunctions"
                ref="tablepageFunctions"
                :default-sort="['name', 'asc']">

                <template slot-scope="pageFunc">

                  <b-table-column field="status">
                    <b-switch type="is-success" v-model="pageFunc.row.status" @input="toggleFlag('function', page.row, pageFunc.row)"></b-switch>
                  </b-table-column>

                  <b-table-column field="acesso">
                    <!-- <span :class="{'tag is-success': pageFunc.row.active, 'tag is-danger': !pageFunc.row.active}">
                      {{ (pageFunc.row.active) ? "Ativo" : "Inativo" }}
                    </span> -->
                  </b-table-column>
<!-- 
                  <b-table-column field="id" right numeric>
                    {{ pageFunc.row.id }}
                  </b-table-column>
 -->
                  <b-table-column field="name" left>
                    {{ pageFunc.row.name }}
                  </b-table-column>

                  <b-table-column field="description" left>
                    {{ pageFunc.row.description }}
                  </b-table-column>

                </template>
              </b-table>
            </td>
          </tr>
        </template>

        <template slot="empty">
            <section class="section">
                <div class="content has-text-grey has-text-centered">
                    <p>
                        <b-icon
                            icon="emoticon-sad"
                            size="is-large">
                        </b-icon>
                    </p>
                    <p>Nenhum Registro.</p>
                </div>
            </section>
        </template>
      </b-table>
    </div>

  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AccessForm",
  props: {
    'plansFormAction': {
      type: String,
      default: () => ""
    },
    'access': {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      render: false,
      isLoading: false,
      accessTable: []
    };
  },
  async created() {
    let loader = this.$loading.show()

    this.accessTable = await this.getAllAccess();

    if (this.access){
      await this.access.map(a => {
        if (a.page){
          this.accessTable.map(aT => {
            if (aT.id == a.page.id){
              aT.status = true
              if (a.pageFunction){
                aT.pageFunctions.map(pF => {
                  if (pF.id == a.pageFunction.id){
                    pF.status = true
                  }
                })
              }
            }
          })
        }
      })
      await this.accessTable.map(aT => {
        if (aT.status){
          let pageFunc = aT.pageFunctions.filter(pF => pF.status)
          aT.acesso = (pageFunc.length == aT.pageFunctions.length) ? 'Total' : 'Parcial'
        } else {
          aT.acesso = 'Restrito'
        }
      })
    }

    this.render = (this.accessTable)
    loader.hide()
  },
  methods: {
    ...mapActions(["getAllAccess"]),
    toggleFlag(type, page, pageFunc){

      let selected = (type == "page") ? page : pageFunc

      if (type == "page"){
        selected.pageFunctions.map(f => {
          f.status = selected.status
        })
        selected.acesso = (selected.status) ? 'Total' : 'Restrito'
      } else {
        page.status = selected.status
        page.pageFunctions.map(f => {
          if (f.status){
            page.status = f.status
          }
        })
        if (page.status){
          let pageFunc = page.pageFunctions.filter(f => f.status)
          page.acesso = (pageFunc.length == page.pageFunctions.length) ? 'Total' : 'Parcial'
        } else {
          page.acesso = 'Restrito'
        }
      }

      this.updateForm()
      this.$refs.tableAccess.openDetailRow(page)
    },
    updateForm(){

      let accessReturn = []
      this.accessTable.map(p => {
        if (p.status){
          accessReturn.push({
            page: {
              id: p.id,
              slug: p.slug,
              nome: p.nome
            },
            pageFunction: {
              id: null,
              slug: null,
              nome: null
            },
            slug: p.slug
          })
        }
        p.pageFunctions.map(f => {
          if (f.status){
            accessReturn.push({
              page: {
                id: f.page.id,
                slug: f.page.slug,
                nome: f.page.nome
              },
              pageFunction: {
                id: f.id,
                slug: f.slug,
                nome: f.nome
              },
              slug: f.page.slug +"."+ f.slug
            })
          }
        })
      })
      this.$emit("change", accessReturn);
    }
  }
};
</script>
