<template>
  <section id="CupomForm">
    <div v-if="renderTable && form" class="has-background-dark">

      <div class="toolbar">
        <b-button v-if="plansFormAction !== 'view'" type="is-success" size="is-small" @click="addCupom()">Adicionar Cupom</b-button>
      </div>

      <b-table
          :data="form"
          ref="tableCupom"
          detailed
          custom-detail-row
          :default-sort="['id', 'asc']"
          detail-key="codigo"
          :show-detail-icon="true">

          <template slot-scope="props" slot="header">
            <div v-if="props.column.sortable" class="label-table centralize-row">
              <span v-if="props.column.sortable">
                <b-icon icon="sort" pack="fas" size="is-small"></b-icon>
              </span>
              <p>{{ props.column.label }}</p>
            </div>
            <div v-else class="label-table centralize-row">
              <p>{{ props.column.label }}</p>
            </div>
          </template>

          <template slot-scope="props">
  
            <b-table-column field="codigo" label="Código" centered>
              <b-field>
                <b-input v-model="props.row.codigo" :disabled="plansFormAction == 'view'" required></b-input>
              </b-field>
            </b-table-column>

            <b-table-column field="descricao" label="Descrição" centered>
              <b-field>
                <b-input v-model="props.row.descricao" :disabled="plansFormAction == 'view'" required></b-input>
              </b-field>
            </b-table-column>

            <b-table-column field="desconto" label="Desconto" centered>
              <b-field>
                <b-input type="number"  maxlength='9999' max='999.99' step='0.01' v-model="props.row.desconto" :disabled="plansFormAction == 'view'" required></b-input>
              </b-field>
            </b-table-column>

            <b-table-column field="valor_bonus" label="Valor Bônus" centered>
              <b-field>
                <b-input type="number"  maxlength='9999' max='999.99' step='0.01' v-model="props.row.valor_bonus" :disabled="plansFormAction == 'view'"></b-input>
              </b-field>
            </b-table-column>

            <b-table-column field="vigencia_ini" label="Vigência Inicial" centered>
              <b-field>
                <b-datetimepicker v-model="props.row.vigencia_ini" icon="calendar-today" editable trap-focus :disabled="plansFormAction == 'view'" required></b-datetimepicker>
              </b-field>
            </b-table-column>

            <b-table-column field="vigencia_fim" label="Vigência Final" centered>
              <b-field>
                <b-datetimepicker v-model="props.row.vigencia_fim" icon="calendar-today" editable trap-focus :disabled="plansFormAction == 'view'"></b-datetimepicker>
              </b-field>
            </b-table-column>

            <b-table-column field="qtd_ativo" label="Qtd. Ativo" centered>
              {{props.row.qtd_ativo}}
            </b-table-column>

            <b-table-column field="qtd_limite" label="Qtd. Limite" centered>
              <b-field>
                <b-input type="number" v-model="props.row.qtd_limite" :disabled="plansFormAction == 'view'"></b-input>
              </b-field>
            </b-table-column>

            <b-table-column field="origem" label="Origem" centered>
              <b-field>
                <b-input v-model="props.row.origem" :disabled="plansFormAction == 'view'"></b-input>
              </b-field>
            </b-table-column>

            <b-table-column field="acoes" label="Ações" centered>
              <b-tooltip label="Excluir Cupom" type="is-danger" position="is-bottom" animated>
                <b-button v-if="plansFormAction !== 'view'" type="is-danger" icon-pack="fas" size="is-small" icon-right="times" outlined @click="delCupom(form, props.index)"/>
              </b-tooltip>
            </b-table-column>

          </template>

          <template slot="detail" slot-scope="props">

            <tr class="detail">

              <td v-if="plansFormAction !== 'view'" class="has-background-primary" style="text-align: center;" @click="addVisitor(props.row)">
                <b-button type="is-primary" icon-pack="fas" size="is-small" icon-left="plus">
                  Convidado
                </b-button>
                <!-- <b-upload v-model="props.row.file" @input="addVisitorCSV(props.row.file)">
                    <a class="button is-primary">
                        <b-icon icon="upload"></b-icon>
                        <span>Selecione CSV</span>
                    </a>
                </b-upload> -->
              </td>
              <td v-else class="has-background-primary" style="text-align: center;">
                <div>Convidado(s)</div>
              </td>
              
              <td colspan="8">

                <b-table
                  :data="props.row.convidados"
                  ref="tableVisitor"
                  :default-sort="['nome', 'asc']">

                  <template slot-scope="visitor">

                    <b-table-column field="nome" centered>
                      <b-field>
                        <div class="detail-label">Nome</div>
                        <b-input size="is-small" v-model="visitor.row.nome" :disabled="plansFormAction == 'view'"></b-input>
                      </b-field>
                    </b-table-column>

                    <b-table-column field="email" centered>
                      <b-field>
                        <div class="detail-label">E-mail</div>
                        <b-input size="is-small" v-model="visitor.row.email" :disabled="plansFormAction == 'view'"></b-input>
                      </b-field>
                    </b-table-column>

                    <b-table-column field="telefone" centered>
                      <b-field>
                        <div class="detail-label">Telefone</div>
                        <b-input size="is-small" v-model="visitor.row.telefone" :disabled="plansFormAction == 'view'"></b-input>
                      </b-field>
                    </b-table-column>

                    <b-table-column field="cpf" centered>
                      <b-field>
                        <div class="detail-label">CPF</div>
                        <b-input size="is-small" v-model="visitor.row.cpf" :disabled="plansFormAction == 'view'"></b-input>
                      </b-field>
                    </b-table-column>

                    <b-table-column field="acoes" centered>
                      <b-tooltip label="Excluir Convidado" type="is-danger" position="is-bottom" animated>
                        <b-button v-if="plansFormAction !== 'view'" type="is-danger" icon-pack="fas" size="is-small" icon-right="times" outlined @click="delVisitor(props.row.convidados, visitor.index)"/>
                      </b-tooltip>
                    </b-table-column>

                  </template>
              
                </b-table>
              </td>
            </tr>
          </template>

      </b-table>
    </div>
  </section>
</template>

<script>
export default {
  name: "CupomForm",
  props: {
    'plansFormAction': {
      type: String,
      default: () => ""
    },
    'cupons': {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      renderTable: false,
      form: []
    };
  },
  created(){

    if (this.cupons){

      let loader = this.$loading.show()
      this.form = JSON.parse(JSON.stringify(this.cupons))
      this.form.map(c => {
        c.vigencia_ini = (c.vigencia_ini) ? new Date(c.vigencia_ini) : null
        c.vigencia_fim = (c.vigencia_fim) ? new Date(c.vigencia_fim) : null
      })
      this.renderTable = true
      loader.hide()
    }
  },
  watch: {
    form: {
      handler: function(newVal, oldVal) {
        this.$emit("change", newVal);
      },
      deep: true
    }
  },
  methods: {
    addCupom() {
      this.form.push({});
    },
    addVisitor(cupom) {
      if(!cupom.convidados){
        cupom.convidados = []
      }
      cupom.convidados.push({})
      this.$refs.tableCupom.openDetailRow(cupom)
    },
    // async addVisitorCSV(file) {
    //   console.log(file)
    //   let content = await file.arrayBuffer()
    //   console.log(content)
    // },
    delCupom(cupom, index){
      cupom.splice(index,1)
      // cupom[index].delete = true
      // cupom = cupom.filter(c => !c.delete)
      // console.log(cupom)
    },
    delVisitor(visitor, index){
      visitor.splice(index,1)
      // visitor[index].delete = true
      // visitor = visitor.filter(v => !v.delete)
    }
  }
};
</script>

<style scoped>
#CupomForm .toolbar {
  text-align: right;
  padding: 1.0em;
}
#CupomForm .detail-label {
  display: inline-table; 
  font-size: 1.0em; 
  font-weight: bold; 
  margin-right: 15px;
  vertical-align: middle;
}
</style>
